import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { get, post } from "@/services/api";
import { retornaRotaSucesso } from "../checkout/PagamentoHelper";
import { ehAreaLogadaCliente, getContextTypeUser } from "@/services/ambiente";
import { useSelector } from "react-redux";
import { getPrimaryColorButton } from "@/services/whitelabel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  activeSpinner,
  setInfoDialogConfirmacao,
  cancelSpinner,
  setInfoToaster,
  setInfoDialog,
} from "@/store/reducers/geral";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import AlertTitle from "@mui/material/AlertTitle";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Logo from "@/components/Logo";
import "./ConfirmaContratacao.scss";

export default function ConfirmaContratacao() {
  const { idRequest, token } = useParams();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [carregar, setCarregar] = useState(false);
  const [dadosUsuario, setDadosUsuario] = useState({});
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const carregarDados = async () => {
      try {
        dispatch(activeSpinner());
        const result = await get(`public/request-info/${idRequest}/${token}`);
        dispatch(cancelSpinner());
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        if (!result.requireContract) {
          navigation(`${getContextTypeUser()}/operacao-finalizada`);
          return;
        }
        setDadosUsuario(result);
      } catch (e) {
        console.error(e);
        dispatch(cancelSpinner());
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Ocorreu um erro ao consultar seus dados. Por favor atualize a página!",
          })
        );
      }
    };

    if (carregar && token && idRequest) {
      setCarregar(false);
      carregarDados();
      return;
    }
  }, [carregar, dispatch, token, idRequest, navigation]);

  useEffect(() => {
    setCarregar(true);
  }, []);

  const direcionaParaConfirmarContratacao = async () => {
    dispatch(activeSpinner());
    const url = await retornaRotaSucesso(dadosUsuario, idRequest, token, true);
    dispatch(cancelSpinner());
    navigation(url);
  };

  const cancelar = async () => {
    dispatch(
      setInfoDialogConfirmacao({
        open: true,
        descricao:
          "Confirma o cancelamento da contratação? Essa ação não poderá ser desfeita.",
        acaoSim: async () => {
          try {
            dispatch(activeSpinner());
            let result;

            if (ehAreaLogadaCliente()) {
              result = await post(
                `secure/requests/cancel/${idRequest}/${token}`
              );
            } else {
              result = await post(
                `public/requests/cancel/${idRequest}/${token}`
              );
            }
            dispatch(cancelSpinner());
            if (result.error) {
              dispatch(setInfoDialog({ open: true, descricao: result.error }));
              return;
            }
            dispatch(
              setInfoToaster({
                open: true,
                message: "Operação cancelada com sucesso!",
                type: "success",
              })
            );

            dispatch(
              setInfoDialog({
                open: true,
                descricao:
                  "Operação cancelada com sucesso! Em breve seu limite será liberado!",
                acaoOk: () => {
                  if (ehAreaLogadaCliente()) {
                    navigation("/app/area-cliente");
                  } else {
                    navigation("/app/simulacao");
                  }
                },
              })
            );
          } catch (e) {
            console.log(e);
            dispatch(cancelSpinner());
            dispatch(
              setInfoDialog({
                open: true,
                descricao: "Ocorreu um erro ao salvar",
              })
            );
          }
        },
      })
    );
  };

  return (
    <Container
      component="main"
      maxWidth="md"
      style={{ maxWidth: "500px" }}
      id="finish-checkout-screen"
      className="sessao-finalizacao"
      sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 2 }, p: { xs: 2, md: 3 } }}>
        <Grid container spacing={1}>
          <Grid item size={12} align="center">
            <Logo style={{ maxWidth: "155px" }} />
          </Grid>
          <Grid item size={12}>
            <Alert severity="warning" style={{ width: "100%" }}>
              <AlertTitle>
                A transação no cartão foi autorizada com sucesso!
              </AlertTitle>
              <Typography>
                Para sua segurança será necessária uma análise da operação que
                pode levar até 48 horas úteis.
              </Typography>
            </Alert>
          </Grid>
          <Grid item size={12} className="botoes-opcoes-confirm-request">
            <Grid
              className="option-confirm-request"
              onClick={() => {
                direcionaParaConfirmarContratacao();
              }}
              sx={{
                backgroundColor: getPrimaryColorButton(user),
              }}>
              <Grid container spacing={1}>
                <Grid item size={10}>
                  <Typography variant="h5">
                    QUERO AGUARDAR A ANÁLISE!
                  </Typography>
                  <Typography variant="span">
                    Confirmar a contratação
                  </Typography>
                </Grid>

                <Grid
                  item
                  size={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}>
                  <ArrowForwardIosIcon />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              className="option-cancel-request"
              onClick={() => {
                cancelar();
              }}>
              <Grid container spacing={1}>
                <Grid item size={10}>
                  <Typography variant="h5">
                    NÃO QUERO AGUARDAR A ANÁLISE!
                  </Typography>
                  <Typography variant="span">Cancelar a contratação</Typography>
                </Grid>
                <Grid
                  item
                  size={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}>
                  <ArrowForwardIosIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
