import Grid from "@mui/material/Grid2";
import { useSelector } from "react-redux";
export default function Logo(props) {
  const user = useSelector((state) => state.user);
  const inicio = () => {
    window.location.href = user.urlLogo;
  };

  return (
    <Grid sx={{ maxWidth: "180px", display: "flex" }}>
      {user?.logos?.lightBackground && (
        <img
          onClick={inicio}
          src={user.logos.lightBackground}
          style={{ maxWidth: "100%", marginTop: "auto", marginBottom: "auto" }}
          alt=""
          {...props}></img>
      )}
    </Grid>
  );
}
