import PropTypes from "prop-types";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import { getTopBarColor } from "@/services/whitelabel";
import { useSelector } from "react-redux";

const DrawerHeader = ({ open }) => {
  const user = useSelector((state) => state.user);

  return (
    <DrawerHeaderStyled
      style={{ backgroundColor: getTopBarColor(user) }}
      open={open}
    />
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
