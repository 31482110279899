import { Alert, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function CamposInvalidos({ formik }) {
  const camposInvalidos = () => {
    return Object.values(formik.errors).length > 0 && formik.submitCount > 0;
  };

  return (
    <Grid container spacing={2} sx={{ mt: 1 }}>
      {camposInvalidos() && (
        <Grid item size={12}>
          <Alert severity="error">
            <Typography>
              Verifique se os campos obrigatórios foram preenchidos
              corretamente!
            </Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}
