import MinimalLayout from "@/layout/MinimalLayout";
import { lazy } from "react";
import Loadable from "@/components/Loadable";
import ConfirmaContratacao from "../pages/common/simulacao/ConfirmaContratacao";

const Login = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/login/Login"))
);
const PoliticaIndicacao = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/politica-indicacao"))
);
const SocialLoginForm = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/login/SocialLoginForm"))
);

const SimulacaoClienteSemLogin = Loadable(
  lazy(() =>
    import("@/pages/cliente/areaAberta/simulacao/SimulacaoClienteSemLogin")
  )
);
const CadastroParceiro = Loadable(
  lazy(() => import("@/pages/parceiro/areaAberta/cadastro-parceiro"))
);
const Finalizacao = Loadable(
  lazy(() => import("@/pages/common/simulacao/Finalizacao"))
);
const RecuperarSenha = Loadable(
  lazy(() =>
    import("@/pages/cliente/areaAberta/recuperacao-senha/RecuperarSenha")
  )
);

const EsqueciMinhaSenha = Loadable(
  lazy(() =>
    import("@/pages/cliente/areaAberta/esqueci-minha-senha/EsqueciMinhaSenha")
  )
);
const ResgatarCupom = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/resgatar-cupom"))
);
const Pagamento = Loadable(
  lazy(() => import("@/pages/common/checkout/Pagamento"))
);

const Pagamento3ds = Loadable(
  lazy(() => import("@/pages/common/checkout/Pagamento3ds"))
);

const Cadastro = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/cadastro"))
);
const LinkPagamento = Loadable(
  lazy(() => import("@/pages/common/simulacao/LinkPagamento"))
);
const InicioLogin = Loadable(
  lazy(() => import("@/pages/cliente/areaAberta/login/Inicio"))
);

const SatisfactionResearch = Loadable(
  lazy(() => import("@/pages/common/satisfaction-research"))
);

const FinalizacaoSemValidacaoMesa = Loadable(
  lazy(() => import("@/pages/common/simulacao/FinalizacaoSemValidacaoMesa"))
);

const AreaAbertaRoutes = {
  path: "app",
  element: <MinimalLayout />,
  children: [
    {
      path: "",
      element: <InicioLogin />,
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "login-social/:id/:token/:idRequestBind/:tokenRequestBind",
      element: <SocialLoginForm />,
    },
    {
      path: "login-social/:id/:token",
      element: <SocialLoginForm />,
    },

    {
      path: "simulacao",
      element: <SimulacaoClienteSemLogin />,
    },

    {
      path: "simulacao/ref/:externalRef",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "simulacao/:tokenSimulacao",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "indicacao/:tokenIndicacao",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "simulacao/:idRequest/:tokenRequest/etapa/:etapa",
      element: <SimulacaoClienteSemLogin />,
    },
    {
      path: "simulacao/etapa/:etapa",
      element: <SimulacaoClienteSemLogin />,
    },

    {
      path: "politica-indicacao",
      element: <PoliticaIndicacao />,
    },
    {
      path: "pagamento/:idRequest/:token",
      element: <Pagamento />,
    },
    {
      path: "pagamento/:idRequest/:token/:gateway",
      element: <Pagamento />,
    },
    {
      path: "pagamento/erro/:idRequest/:token",
      element: <Pagamento />,
    },

    {
      path: "pagamento-ep/:idRequest/:token",
      element: <Pagamento3ds />,
    },
    {
      path: "pagamento-ep/:idRequest/:token/:originCall",
      element: <Pagamento3ds />,
    },
    {
      path: "pagamento/erro/:idRequest/:token/:gateway",
      element: <Pagamento />,
    },

    {
      path: "recuperar-senha/:token",
      element: <RecuperarSenha />,
    },
    {
      path: "esqueci-minha-senha",
      element: <EsqueciMinhaSenha />,
    },
    {
      path: "cadastro/parceiro/:idCompany/:tokenCompany",
      element: <Cadastro />,
    },
    {
      path: "cadastro/:idRequest/:token",
      element: <Cadastro />,
    },

    {
      path: "confirmacao/:idRequest/:token",
      element: <Finalizacao />,
    },
    {
      path: "confirma-contratacao/:idRequest/:token",
      element: <ConfirmaContratacao />,
    },

    {
      path: "operacao-finalizada",
      element: <FinalizacaoSemValidacaoMesa />,
    },
    {
      path: "cadastro",
      element: <Cadastro />,
    },
    {
      path: "parceiro/indicacao/:originCompany/:tokenCompany",
      element: <CadastroParceiro />,
    },
    {
      path: "resgatar-cupom/:token",
      element: <ResgatarCupom />,
    },
    {
      path: "link-pagamento",
      element: <LinkPagamento />,
    },
    {
      path: "pesquisa-satisfacao",
      element: <SatisfactionResearch />,
    },
    {
      path: "pesquisa-satisfacao/:type",
      element: <SatisfactionResearch />,
    },
    {
      path: "*",
      element: <InicioLogin />,
    },
  ],
};

export default AreaAbertaRoutes;
