import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Backdrop from "@mui/material/Backdrop";

function CircularProgressWithLabel(props) {
  return (
    <Backdrop sx={{ color: "#fff", zIndex: 99999 }} open={props.activeLoading}>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              component="div"
              sx={{ color: "inherit" }}>
              {`${Math.round(props.value)}%`}
            </Typography>
          </Grid>
          {!!props.message && (
            <Grid item>
              <Typography
                variant="caption"
                component="div"
                sx={{ color: "inherit" }}>
                {props.message}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Backdrop>
  );
}

export default function LoadingWithProgress({
  activeLoading,
  progress,
  messageProgress,
}) {
  return (
    <CircularProgressWithLabel
      value={progress}
      message={messageProgress}
      activeLoading={activeLoading}
    />
  );
}
