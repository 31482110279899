import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import { useSelector } from "react-redux";
import { ehAreaParceiro } from "@/services/ambiente";
export default function ContatoWhatsApp() {
  const user = useSelector((state) => state.user);
  const whatsapp = !ehAreaParceiro() ? user.whatsappSuport : "55999042290";

  return (
    <>
      {!user.isQuitaboletos && (
        <Grid
          container
          spacing={0}
          sx={{ marginTop: "3rem", cursor: "pointer" }}
          onClick={() => {
            window.open(
              `https://api.whatsapp.com/send?phone=55${whatsapp}&text=Ol%C3%A1. Preciso de ajuda com o portal!`
            );
          }}>
          <>
            <Grid item size={12}>
              <Typography align="center">Dúvidas?</Typography>
            </Grid>
            <Grid item size={12}>
              <Typography align="center">Entre em contato conosco:</Typography>
            </Grid>
          </>
          <Grid item size={12} align="center">
            <img src="/whatsapp.png" style={{ width: "50px" }} alt=""></img>
          </Grid>
        </Grid>
      )}
    </>
  );
}
