import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";

// project import
import DrawerHeaderStyled from "./DrawerHeaderStyled";
import { getTopBarColor } from "@/services/whitelabel";
const DrawerHeader = ({ open }) => {
  const user = useSelector((state) => state.user);

  return (
    <DrawerHeaderStyled
      style={{ backgroundColor: getTopBarColor(user) }}
      open={open}>
      <Typography
        style={{
          color: "#f0f0f0",
          whiteSpace: "normal",
          fontSize: "0.75rem",
        }}>{`${user.companyBase.idCompany} - ${user.companyBase.companyName}`}</Typography>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool,
};

export default DrawerHeader;
