import { CircularProgress, Container, Paper, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setInfoDialog } from "@/store/reducers/geral";
import { get } from "@/services/apiCreditoPessoal";
import Logo from "@/components/Logo";
import Grid from "@mui/material/Grid2";

export default function AguardandoAnalise() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { id, token } = useParams();

  useEffect(() => {
    const verifyPersonalCredit = async () => {
      try {
        const result = await get(`public/status-pre-analysis/${id}/${token}`);

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          return;
        }
        if (result.finished) {
          navigation(
            `/app/credito-pessoal/pre-analise-finalizada/${id}/${token}`
          );
        }
      } catch (e) {
        console.log(e);
        dispatch(
          setInfoDialog({
            open: true,
            descricao:
              "Não foi possível buscar o status da proposta. Por favor atualize a página ou entre em contato com o suporte!",
          })
        );
      }
    };

    const interval = setInterval(() => {
      verifyPersonalCredit();
    }, 3000);
    return () => clearInterval(interval);
  }, [dispatch, navigation, id, token]);

  return (
    <Container maxWidth="md" fullWidth>
      <Paper sx={{ p: 2, my: 4 }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}>
          <Grid item size={12} align="center">
            <Logo style={{ maxWidth: "155px" }} />
          </Grid>
          <Grid item sx={{ mt: 2 }}>
            <CircularProgress size={80} />
          </Grid>
          <Grid item textAlign="center">
            <Typography variant="h6">
              Verificando status da proposta. Por favor aguarde!
            </Typography>
            <Typography variant="h6">
              Este processo pode levar até 2 minutos!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
