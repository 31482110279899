import { useRoutes } from "react-router-dom";

import AreaAbertaParceiroRoutes from "./AreaAbertaParceiroRoutes";
import AreaAbertaRoutes from "./AreaAbertaRoutes";
import DashboardClienteRoutes from "./DashboardClienteRoutes";
import DashboardParceiroRoutes from "./DashboardParceiroRoutes";
import GeralRoutes from "./GeralRoutes";
import CreditoPessoalRoutes from "./CreditoPessoalRoutes";

export default function ThemeRoutes() {
  return useRoutes([
    CreditoPessoalRoutes,
    DashboardParceiroRoutes,
    AreaAbertaRoutes,
    AreaAbertaParceiroRoutes,
    DashboardClienteRoutes,
    GeralRoutes,
  ]);
}
